// Packages:
import React, { memo, useEffect, useState } from "react";
import {
  TextField,
  styled,
  FormControl,
  MenuItem,
  Select,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { MdCancel } from "react-icons/md";

// Styles:
import { VariableField, Variable, FieldName } from "../styles";
import InputPair from "./InputPair";
import uuid from "react-uuid";
import { RegexMapping } from "../../../../constants/regex";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const StyledFormControl = styled(FormControl)`
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
  padding: 0.25rem;
`;

const DEFAULT_OUTPUT_DATETIMEGRID={
  key:"dateTimeGrid",
  value:{
    fieldName:null,
    value:null
  }
}

const DAYMAPPING={
  "Sunday":'0',
  "Monday":'1',
  "Tuesday":'2',
  "Wednesday":'3',
  "Thursday":'4',
  "Friday":'5',
  "Saturday":'6',
} 
// Functions:
const FormInputs = ({ outputs, output, i, updateOutput, isEditable, dynamicFields }) => {
  // Constants:
  const OUTPUTTYPE = {
    STRING: "STRING",
    NUMBER: "NUMBER",
    BOOLEAN: "BOOLEAN",
    MULTIPLE: "MULTIPLE",
    NAME: "NAME",
    ADDRESS: "ADDRESS",
    EMAIL: "EMAIL",
    PHONE_NUMBER: "PHONE NUMBER",
    FORM: "FORM",
    DATE: "DATE",
    TIME: "TIME",
    TEXT: "TEXT",
    DATETIME:"DATETIME",
    CHECKBOXLIST:"CHECKBOXLIST"
  };

  // State:
  const [multipleOptions, setMultipleOptions] = useState("");
  const [dateTimeGrid,setDateTimeDrid]=useState(DEFAULT_OUTPUT_DATETIMEGRID)
  
  const [options, setOptions] = useState([]);
  const [minLength, setMinLength] = useState(
    output.validation?.minLength ?? null
  );
  const [maxLength, setMaxLength] = useState(
    output.validation?.maxLength ?? null
  );
  const [regex, setRegex] = useState(output.validation?.regex ?? null);
  const [isOptional, setIsOptional] = useState(
    output.validation?.isOptional ?? false
  );
  const [timezone, setTimezone] = useState(output?.timezone ?? "");
  const [toggleValidations, setToggleValidations] = useState(false);
  const [minValue, setMinValue] = useState(output.validation?.minValue ?? null);
  const [maxValue, setMaxValue] = useState(output.validation?.maxValue ?? null);
  const [helpinfo,setHelpInfo]=useState(output.validation?.helpinfo??"")
  const [validDays, setValidDays] = useState(output.validation?.validDays??[]);
  const [fieldGroup,setFieldGroup]=useState(output?.fieldGroup??"")

  useEffect(() => {
    setOptions(output.values ?? []);
    setDateTimeDrid(output.dateTimeGrid??DEFAULT_OUTPUT_DATETIMEGRID)
    setMaxLength(output.validation?.maxLength);
    setMinLength(output.validation?.minLength);
    setRegex(output.validation?.regex);
    setIsOptional(output.validation?.isOptional ?? false);
    setTimezone(output.timezone ?? "");
    setMinValue(output.validation?.minValue ?? null);
    setMaxValue(output.validation?.maxValue ?? null);
    setHelpInfo(output.validation?.helpinfo??"")
    setValidDays(output.validation?.validDays??[])
    setFieldGroup(output.fieldGroup??"")
  }, [
    output.values,
    output.dateTimeGrid,
    output.validation?.minLength,
    output.validation?.maxLength,
    output.validation?.regex,
    output.validation?.isOptional,
    output.validation?.helpinfo,
    output.timezone,
    output.minValue,
    output.maxValue,
    output.validDays,
    output.fieldGroup
  ]);

  const handleValidation = (e) => {
    let newValidation = {};
    switch (e.target.name) {
      case "minLength":
        setMinLength(e.target.value);
        newValidation = { minLength: e.target.value, maxLength: maxLength, regex: regex, isOptional: isOptional, helpinfo:helpinfo};
        break;
      case "maxLength":
        setMaxLength(e.target.value);
        newValidation = { maxLength: e.target.value, minLength: minLength, regex: regex, isOptional: isOptional,helpinfo:helpinfo };
        break;
      case "regex":
        setRegex(e.target.value);
        newValidation = { regex: e.target.value, minLength: minLength, maxLength: maxLength, isOptional: isOptional, helpinfo:helpinfo };
        break;
      case "checkbox":
        const isChecked = e.target.checked;
        setIsOptional(e.target.checked);
      switch (output.type) {
        case OUTPUTTYPE.DATE:
            newValidation = { isOptional: isChecked,validDays: validDays,helpinfo: helpinfo };
            break;
        case OUTPUTTYPE.STRING:
            newValidation = { isOptional: isChecked,minLength: minLength,maxLength: maxLength,regex: regex,helpinfo: helpinfo };
          break;
        case OUTPUTTYPE.NUMBER:
          newValidation = { isOptional: isChecked, minValue: minValue, maxValue: maxValue,helpinfo: helpinfo };
          break;
        default:
          newValidation = { isOptional: isChecked, helpinfo: helpinfo };
          break;
      }
        
        break;
      case "minValue":
        setMinValue(e.target.value);
        newValidation = { minValue: e.target.value, maxValue: maxValue, isOptional: isOptional, helpinfo:helpinfo };
        break;
      case "maxValue":
        setMaxValue(e.target.value);
        newValidation = {minValue: minValue, maxValue: e.target.value, isOptional: isOptional, helpinfo:helpinfo };
        break;
      case "helpinfo":
        setHelpInfo(e.target.value);
        switch (output.type) {
          case OUTPUTTYPE.DATE:
            newValidation = { isOptional: isOptional,validDays: validDays,helpinfo: e.target.value };
            break;
          case OUTPUTTYPE.STRING:
            newValidation = { isOptional: isOptional,minLength: minLength,maxLength: maxLength,regex: regex,helpinfo: e.target.value };
            break;
          case OUTPUTTYPE.NUMBER:
            newValidation = { isOptional: isOptional,minValue: minValue,maxValue: maxValue,helpinfo: e.target.value };
            break;
          default:
            newValidation = { isOptional: isOptional, helpinfo: e.target.value };
          }
            break;
        case 'validDays':
            const isSelected = validDays.includes(e.target.value);
            let validArray;
            if (isSelected) {
              validArray = validDays.filter(selectedDay => selectedDay !== e.target.value);
            } else {
              validArray = [...validDays, e.target.value];
            }
            setValidDays(validArray);
            newValidation = { isOptional: isOptional, helpinfo: helpinfo, validDays: validArray };
        break;
      default:
        break;
    }
    const tempOutputs = outputs.map((outputItem, index) => {
      if (index === i) {
        const updatedOutput = { ...outputItem };
            if (updatedOutput?.validation) {
          delete updatedOutput.validation;
        }
        return {
          ...updatedOutput,
          validation: {
            ...(updatedOutput.validation ? updatedOutput.validation : {}),
            ...(updatedOutput.type === output.type ? newValidation : {}),
          },
        };
      }
      return outputItem;
    });

    updateOutput(tempOutputs);
  };

  const handleTimeZoneSelect = (e) => {
    const val = e.target.value;
    setTimezone(val);
    const tempOutputs = outputs.map((outputItem, index) => {
      if (index === i) {
        return { ...outputItem, timezone: val };
      }
      return outputItem;
    });
    updateOutput(tempOutputs);
  };

  const optionOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = multipleOptions.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !options?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setOptions((prevState) => [...prevState, trimmedInput]);
      setMultipleOptions("");
      const tempOutputs = outputs.map((outputItem, index) => {
        if (index === i) {
          return { ...outputItem, values: [...options, trimmedInput] };
        }
        return outputItem;
      });
      updateOutput(tempOutputs);
    }
  };

  const handleOptionValueChange = (e) => {
    const { value } = e.target;
    setMultipleOptions(value);
  };

  const deleteOption = (index) => {
    setOptions((prevState) => prevState.filter((option, i) => i !== index));
    const tempOutputs = outputs.map((outputItem, outputIndex) => {
      if (outputIndex === i) {
        return {
          ...outputItem,
          values: options.filter((_, filterIndex) => filterIndex !== index),
        };
      }
      return outputItem;
    });
    updateOutput(tempOutputs);
  };

  const handleAutocompleteChange = (event, value, setStateFunction, defaultOutput) => {
    if (!value) {
        setStateFunction(defaultOutput);
        return;
    }
    const isDynamic = dynamicFields.includes(value);
    const newValue = isDynamic
        ? { fieldName: value, value: null }
        : { fieldName: null, value };

    setStateFunction({ key: defaultOutput.key, value: newValue });

    const tempOutputs = outputs.map((outputItem, index) => {
        if (index === i) {
            return { ...outputItem, dateTimeGrid: { key: 'dateTimeGrid', value: newValue } };
        }
        return outputItem;
    });
    updateOutput(tempOutputs);
};

  // Return:
  return (
    <div>
      <div key={i}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {output.type !== "NAME" && output.type !== "ADDRESS" && (
              <StyledTextField
                id="table-name"
                variant="outlined"
                disabled={!isEditable}
                placeholder="Name"
                value={output.field}
                onChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const newOutputs = [...outputs];
                  newOutputs[i] = {
                    ...output,
                    field: event.currentTarget.value,
                  };
                  updateOutput(newOutputs);
                }}
                size="small"
                sx={{ mb: "0" }}
              />
            )}

            <StyledFormControl size="small" fullWidth>
              <Select
                id="table-type"
                value={output.type}
                disabled={!isEditable}
                onChange={(event) => {
                  let newOutputs = [...outputs];
                  if (event.target.value === "NAME") {
                    newOutputs[i] = {
                      ...output,
                      type: event.target.value,
                      fields: [
                        {
                          id: uuid(),
                          field: "",
                          type: "NAME",
                          message: "Enter first Name",
                        },
                        {
                          id: uuid(),
                          field: "",
                          type: "NAME",
                          message: "Enter last Name",
                        },
                      ],
                    };
                    delete newOutputs[i]["message"];
                    delete newOutputs[i]["field"];
                  } else if (event.target.value === "ADDRESS") {
                    newOutputs[i] = {
                      ...output,
                      type: event.target.value,
                      fields: [
                        {
                          id: uuid(),
                          field: "",
                          type: "ADDRESS",
                          message: "Enter address line 1",
                        },
                        {
                          id: uuid(),
                          field: "",
                          type: "ADDRESS",
                          message: "Enter address line 2",
                        },
                        {
                          id: uuid(),
                          field: "",
                          type: "ADDRESS",
                          message: "Enter city",
                        },
                        {
                          id: uuid(),
                          field: "",
                          type: "ADDRESS",
                          message: "Enter state",
                        },
                        {
                          id: uuid(),
                          field: "",
                          type: "ADDRESS",
                          message: "Enter postal code",
                        },
                      ],
                    };
                    delete newOutputs[i]["message"];
                    delete newOutputs[i]["field"];
                  } else {
                    newOutputs[i] = {
                      ...output,
                      type: event.target.value,
                    };
                  }
                  updateOutput(newOutputs);
                }}
              >
                {Object.values(OUTPUTTYPE).map((type, index) => (
                  <StyledMenuItem key={index} value={type}>
                    {type}
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </div>

          {output.type !== "NAME" &&
            output.type !== "ADDRESS" &&
            output.type !== "FORM" && (
              <StyledTextField
                id="table-message"
                variant="outlined"
                disabled={!isEditable}
                placeholder="Message"
                value={output.message}
                onChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const newOutputs = [...outputs];
                  newOutputs[i] = {
                    ...output,
                    message: event.currentTarget.value,
                  };
                  updateOutput(newOutputs);
                }}
                size="small"
                sx={{ mb: "0" }}
              />
            )}
        </div>

        {(output.type === "MULTIPLE" || output.type === "CHECKBOXLIST") && (
          <VariableField>
            {options.map((option, index) => (
              <Variable key={index}>
                {option}
                <MdCancel
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteOption(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={multipleOptions}
              disabled={!isEditable}
              placeholder="Enter an option"
              onKeyDown={optionOnKeyDown}
              onChange={handleOptionValueChange}
              size="small"
              sx={{ marginBottom: "0" }}
              fullWidth
            />
          </VariableField>
        )}
        {output.type === "DATETIME" && (
          <VariableField>
            <Autocomplete
              options={dynamicFields}
              disabled={!isEditable}
              value={dateTimeGrid?.value?.fieldName ?? dateTimeGrid?.value?.value}
              fullWidth
              freeSolo
              onChange={(event, value) => handleAutocompleteChange(event, value, setDateTimeDrid, DEFAULT_OUTPUT_DATETIMEGRID)}
              onInputChange={(event, value) => handleAutocompleteChange(event, value, setDateTimeDrid, DEFAULT_OUTPUT_DATETIMEGRID)}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  placeholder="Select DATE TIME..."
                  variant="outlined"
                  size="small"
                  style={{ padding: 0 }}
                  sx={{ mb: 0 }}
                />
              )}
            />
          </VariableField>
        )}

        {(output.type === "DATE" || output.type === "TIME") && (
          <>
            <StyledFormControl size="small" fullWidth>
              <Select
                id="time-zone"
                disabled={!isEditable}
                value={timezone}
                onChange={handleTimeZoneSelect}
                style={{ fontSize: 15 }}
              >
                <MenuItem value="">Select Timezone...</MenuItem>
                {Intl.supportedValuesOf("timeZone").map((val) => (
                  <StyledMenuItem key={val} value={val}>
                    {val}
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </>
        )}

        {(output.type === "NAME" || output.type === "ADDRESS") && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "5px",
              }}
            >
              {output?.fields?.map((item) => (
                <InputPair
                  key={item.id}
                  isEditable={isEditable}
                  item={item}
                  outputs={outputs}
                  output={output}
                  updateOutput={updateOutput}
                />
              ))}
            </div>
          </>
        )}
        <FieldName
          style={{
            marginTop: "0.25rem",
            border: "1px solid gray",
            backgroundColor: "lightGray",
            padding: 5,
            cursor: "pointer",
            marginBottom: 0,
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: toggleValidations ? 0 : 5,
            borderBottomRightRadius: toggleValidations ? 0 : 5,
            borderBottom: toggleValidations ? null : "1px solid gray",
          }}
          onClick={(e) => setToggleValidations(!toggleValidations)}
        >
          Validations
        </FieldName>
        {toggleValidations && (
          <div
            style={{
              border: "1px solid gray",
              borderBottomRightRadius: 5,
              borderBottomLeftRadius: 5,
              padding:'0.5rem'
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "0.75rem",
              }}
            >
              <input
                type="checkbox"
                name="checkbox"
                checked={isOptional}
                disabled={!isEditable}
                style={{ marginRight: "0.35rem", marginTop: "0.25rem" }}
                onChange={handleValidation}
              />
              <div style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                Is Optional
              </div>
            </div>

            {output.type === "STRING" && (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FieldName>Min Length</FieldName>
                    <StyledTextField
                      type="number"
                      placeholder="minLength"
                      value={minLength}
                      disabled={!isEditable}
                      inputProps={{ min: 0 }}
                      name="minLength"
                      size="small"
                      sx={{ mb: "0" }}
                      onChange={handleValidation}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FieldName>Max Length</FieldName>
                    <StyledTextField
                      type="number"
                      placeholder="MaxLength"
                      value={maxLength}
                      disabled={!isEditable}
                      inputProps={{ min: 0 }}
                      name="maxLength"
                      size="small"
                      sx={{ mb: "0" }}
                      onChange={handleValidation}
                    />
                  </div>
                </div>
                <FieldName>Regex Type</FieldName>
                <StyledFormControl size="small" fullWidth>
                  <Select
                    id="regex-action-select"
                    name="regex"
                    disabled={!isEditable}
                    value={regex}
                    onChange={handleValidation}
                  >
                    {Object.entries(RegexMapping).map(([key, value]) => (
                      <StyledMenuItem key={key} value={value}>
                        {key}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </>
            )}
            {output.type === "NUMBER" && (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FieldName>Min Value</FieldName>
                    <StyledTextField
                      type="number"
                      placeholder="minValue"
                      value={minValue}
                      disabled={!isEditable}
                      inputProps={{ min: 0 }}
                      name="minValue"
                      size="small"
                      sx={{ mb: "0" }}
                      onChange={handleValidation}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FieldName>Max Value</FieldName>
                    <StyledTextField
                      type="number"
                      placeholder="MaxValue"
                      value={maxValue}
                      disabled={!isEditable}
                      inputProps={{ min: 0 }}
                      name="maxValue"
                      size="small"
                      sx={{ mb: "0" }}
                      onChange={handleValidation}
                    />
                  </div>
                </div>
              </>
            )}
          <FieldName>INFO</FieldName>
            <StyledTextField
              type="text"
              placeholder="Enter custom message..."
              value={helpinfo}
              disabled={!isEditable}
              name="helpinfo"
              size="small"
              fullWidth
              sx={{ mb: "0.25rem" }}
              onChange={handleValidation}
            />
            {output.type === "DATE" && (
              <>
                <FieldName>Valid Days</FieldName>
                <StyledFormGroup>
                  {Object.entries(DAYMAPPING).map(([key,value])=>(
                        <FormControlLabel sx={{margin:0}} name="validDays" control={<Checkbox disabled={!isEditable} checked={validDays.includes(value)===true} />} label={key} value={value} onChange={handleValidation}/>
                  ))}
                </StyledFormGroup>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Exports:
export default memo(FormInputs);
