// Packages:
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/auth';
import Switch from 'react-switch';
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import {
  IconButton,
  Tooltip,
  Avatar,
  Popover,
  Button,
  styled,
  Badge,
  Divider
} from '@mui/material';
import { pink } from '@mui/material/colors';
import LogoutIcon from '@mui/icons-material/Logout';

// Constants:
import ROUTES from '../../constants/routes';
import {
  setAgentCustomerChatStatus,
  setUserDetails
} from '../../redux/actions/authActions';
import { handleAgentStatus } from '../../redux/actions/contentActions';
import { SOCKET_URL } from '../../constants/Endpoint';
import "./index.css"

// Styles:
import {
  Bar,
  Title,
  FlexButton,
  Menu,
  StyledLink,
  StyledAnchorTag,
  Brand,
  Toggle,
  Setting
} from './styles';
import { toggleOtpValidationApi, trackActivity } from '../../api';
import { incomingMessage } from '../../redux/actions/chatActions';
import { WebSocketConnectionContext } from '../../App';

const StyledPopover = styled(Popover)`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  & .MuiPaper-root {
    padding: 1.5rem 2rem;
    text-align: center;
  }
`;

const StyledBadgeOn = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    width: '10px',
    height: '10px',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const StyledBadgeOff = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'grey',
    width: '10px',
    height: '10px',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  }
}));

// Functions:
const Navbar = ({ isAuthenticated, user, setAuthStatus, changeUser }) => {
  // Constants:
  const dispatch = useDispatch();
  const organisation_info = useSelector((state) => state?.content?.org_info);
  const organisation_custom_info = useSelector((state) => state.content?.orgCustomInfo);
  const navigate = useNavigate();
  const auth = useAuth();
  const [login, setLogin] = useState(isAuthenticated);
  const BRAND = organisation_info?.org_data?.brand_image_url;
  const validateEndUser = useSelector((state) => state?.content?.org_info?.org_data?.validate_enduser);
  const show_brand_image_url = Object.keys(organisation_info?.org_data||{})?.includes("show_brand_image_url") ? organisation_info?.org_data?.show_brand_image_url : true;
  const chatMedium = organisation_info?.org_data?.chatMedium;
  const user_status_info = useSelector((state) => state.content.agent_status);
  const user_details = useSelector((state) => state.auth.userDetails);
  const userInfo = useSelector((state) => state.auth.user);
  const [checked, setChecked] = useState(true);
  const [settingToggleChecked, setSettingToggleChecked] = useState(validateEndUser);
  const [showSwitch, setshowSwitch] = useState(false);
  const agent_type_info = useSelector((state) => state.content.agent_type);
  const [toggle, setToggle] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSetting, setIsSetting] = useState(null);
  const { openConnection, changeConnection, sendJsonMessage } = useContext(
    WebSocketConnectionContext
  );

  // Event Handlers:
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingClick = (event) => {
    setIsSetting(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleSettingClose = () => {
    setIsSetting(null);
  };

  const open = Boolean(anchorEl);
  const settingOpen = Boolean(isSetting);

  // Functions:
  const handleLogout = useCallback(async () => {
    const activity = {
      orgid: organisation_info?.org_data?._id,
      recordid: `${user?.username?.slice(0, 8)}${new Date().toISOString()}`,
      user_email: `${user?.attributes?.email}`,
      record: JSON.parse(localStorage.getItem('activity'))
    };
    await trackActivity(activity);
    await auth.logout();
    setUserDetails({});
    navigate(ROUTES.INDEX);
  }, [
    auth,
    navigate,
    organisation_info?.org_data?._id,
    user?.attributes?.email,
    user?.username
  ]);

  useEffect(() => {
    if (agent_type_info === 'superadmin' || agent_type_info.toLowerCase() === 'admin' ||agent_type_info === 'supervisor') {
      setshowSwitch(true);
      setAdmin(true);
    } else if (agent_type_info === 'agent') {
      setshowSwitch(true);
      setAdmin(false);
    } else {
      setshowSwitch(false);
    }
  }, [agent_type_info]);

  useEffect(() => {
    if (user_status_info === 'off') {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [user_status_info]);

  useEffect(()=>{
    if(validateEndUser){
      setSettingToggleChecked(true);
    }else{
      setSettingToggleChecked(false);
    }
  }, [validateEndUser])

  const handleChange = (nextChecked) => {
    let status = null;
    let connection = false;
    if (!chatMedium || chatMedium === 'slack') {
      if (nextChecked === false) {
        status = 'off';
      } else {
        status = 'on';
      }
      dispatch(
        handleAgentStatus(
          'update',
          localStorage.getItem('org_id'),
          user_details['email'],
          status
        )
      );
    } else {
      if (nextChecked === false) {
        connection = false;
        status = 'off';
      } else {
        connection = true;
        status = 'on';
      }
      dispatch(
        handleAgentStatus(
          'update',
          localStorage.getItem('org_id'),
          user_details['email'],
          status
        )
      );
      if (
        (openConnection === false && connection === true) ||
        (openConnection === true && connection === false)
      )
        changeConnection(connection);
      const setTypeMessage = {
        action: 'setType',
        orgid: organisation_info?.org_data?._id,
        type: userInfo.role,
        userId: userInfo.id,
        agentEmail: user_details?.['email']
      };
      sendJsonMessage(setTypeMessage);
      dispatch(setAgentCustomerChatStatus(true));
    }
  };

  const handleOtpToggle = async (isOtpToggleActive) => {
    const id = organisation_info?.org_data?._id;
      const res = await toggleOtpValidationApi(id, isOtpToggleActive);
      if(res.status === 200) {
        if (isOtpToggleActive === false) {
          setSettingToggleChecked( res?.data?.result?.validate_enduser|| false)
        } else {
          setSettingToggleChecked(res?.data?.result?.validate_enduser|| false);
        }
      }else{
        console.error("Error", res.message);
      }
  };

  const handleToggle = useCallback(() => {
    if (toggle === true) setToggle(false);
    else setToggle(true);
  }, [toggle]);

  // Return:
  return (
    <Bar background={organisation_custom_info?.navbarBackgroundColor} height={organisation_custom_info?.height} className={organisation_custom_info?.logoPosition==="right"? 'navbar-container item-flow-reverse':'navbar-container item-flow'}> 
     <div className={organisation_custom_info?.logoPosition==="mid"?"logo-container-mid":"logo-container"}>
     {  organisation_custom_info?.fasttrackTextEnabled===false ? <></>:<Title
        onClick={() => {
          navigate(ROUTES.INDEX);
          setToggle(false);
        }}
        style={{color: `${organisation_custom_info?.navbarLinkColor}` || "#ffffff" }}
      >
        FastTrack
      </Title>}
     <Brand 
      onClick={() => {
        if(organisation_info?.org_data?.subdomain_name==="mixt"){
          let a = document.createElement('a')
          a.target= '_blank'
          a.href= 'https://www.mixt.com'
          a.click()
        }else{
          navigate(ROUTES.INDEX);
          setToggle(false);
        }
      }}
      src={show_brand_image_url && BRAND} subdomain={organisation_info?.org_data?.subdomain_name} 
      height={organisation_custom_info?.logoHeight}/>
     </div>
     {showSwitch && (
        <FlexButton>
          <Switch
            onChange={handleChange}
            checked={checked}
            uncheckedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 12,
                  color: '#FFFFFF',
                  paddingRight: 5
                }}
              >
                off
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 12,
                  color: '#FFFFFF',
                  paddingLeft: 5
                }}
              >
                on
              </div>
            }
            offColor="#E53935"
            onColor="#43A047"
          />
        </FlexButton>
      )}
      <Toggle>
        <Tooltip title="Menu">
          <IconButton onClick={handleToggle}>
            <MenuIcon sx={{ color: '#FFFFFF' }} />
          </IconButton>
        </Tooltip>
      </Toggle>
      <Menu toggle={toggle} style={{justifyContent : organisation_custom_info?.logoPosition==="right"?"flex-start":"flex-end"}}>
        {login ? (
          <>
            <StyledLink
              to={ROUTES.AGENT.AI_AGENT}
              onClick={() => setToggle(false)}
              style={{ color: `${organisation_custom_info?.navbarLinkColor}`||"#ffffff" }} 
            >
              AI Agent
            </StyledLink>
            <StyledLink 
             to={ROUTES.AGENT.DASHBOARD} onClick={() => setToggle(false)} 
             style={{ color: `${organisation_custom_info?.navbarLinkColor}`||"#ffffff" }} 
             >
              Analytics
            </StyledLink>
            <StyledLink
              to={ROUTES.AGENT.KNOWLEDGE_V2}
              onClick={() => setToggle(false)}
              style={{ color: `${organisation_custom_info?.navbarLinkColor}`||"#ffffff" }}  
            >
              Knowledge
            </StyledLink>
            <StyledLink
              to={ROUTES.AGENT.WORKFLOWS_V2}
              onClick={() => setToggle(false)}
              style={{ color: `${organisation_custom_info?.navbarLinkColor}`||"#ffffff" }} 
            >
              Workflows
            </StyledLink>
            <StyledLink
              to={ROUTES.AGENT.PERFORMANCE}
              onClick={() => setToggle(false)}
              style={{ color: `${organisation_custom_info?.navbarLinkColor}`||"#ffffff" }} 
            >
              Performance
            </StyledLink>
            {admin && (
              <StyledLink
                to={ROUTES.ADMIN.ACCOUNTS}
                onClick={() => setToggle(false)}
                style={{ color: `${organisation_custom_info?.navbarLinkColor}`||"#ffffff" }} 
              >
                Accounts
              </StyledLink>
            )}
            <StyledAnchorTag
              target="_blank"
              href="https://www.webapp.readyly.com/login"
              rel="noreferrer"
              style={{ color: `${organisation_custom_info?.navbarLinkColor}`||"#ffffff" }} 
            >
              Prism
            </StyledAnchorTag>
            {/* {admin && (<Tooltip title="Setting"> */}
            <Tooltip title="Setting">
              <IconButton
                  size="small"
                  sx={{ ml: '1rem' }}
                  onClick={handleSettingClick}
                >
                <Setting />
              </IconButton>
            </Tooltip>
            {/* </Tooltip>)} */}
            <StyledPopover 
              open={settingOpen}
              anchorEl={isSetting}
              onClose={handleSettingClose}
              elevation={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                width: '100%',
                background:"yellow"
              }}>
                <span style={{marginRight:"5px"}}>
                  Toggle otp:
                </span>
                  <Switch
                    onChange={handleOtpToggle}
                    checked={settingToggleChecked}
                    uncheckedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 12,
                          color: '#FFFFFF',
                          paddingRight: 5
                        }}
                      >
                        off
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 12,
                          color: '#FFFFFF',
                          paddingLeft: 5
                        }}
                      >
                        on
                      </div>
                    }
                    offColor="#E53935"
                    onColor="#43A047"
                  />
            </StyledPopover>
            <Tooltip title="Profile">
              <IconButton
                size="small"
                sx={{ ml: '1rem' }}
                onClick={handleProfileClick}
              >
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                    fontWeight: 'bold',
                    fontSize: '16px',
                    bgcolor: pink[100],
                    color: pink[600]
                  }}
                >
                  {user_details?.email.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
            <StyledPopover
              open={open}
              anchorEl={anchorEl}
              onClose={handleProfileClose}
              elevation={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              { checked ? 
                  <StyledBadgeOn
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        fontSize: '48px',
                        fontWeight: '500',
                        bgcolor: pink[100],
                        color: pink[600],
                        margin: '0 auto'
                      }}
                    >
                      {user_details?.email.charAt(0).toUpperCase()}
                    </Avatar>
                  </StyledBadgeOn>
              :
                  <StyledBadgeOff
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        fontSize: '48px',
                        fontWeight: '500',
                        bgcolor: pink[100],
                        color: pink[600],
                        margin: '0 auto'
                      }}
                    >
                      {user_details?.email.charAt(0).toUpperCase()}
                    </Avatar> 
                  </StyledBadgeOff>
              }
              <div
                style={{
                  marginTop: '1.5rem',
                  fontWeight: '600'
                }}
              >
                {user_details?.email}
              </div>
              <div 
                style={{ 
                  marginTop: '1rem', 
                  fontWeight: '400'
                }}
                >
                  {agent_type_info}
              </div>
              <div 
                style={{ 
                  marginTop: '1rem', 
                  fontWeight: '400'
                }}
                >
                  {organisation_info?.org_data?.orgName}
              </div>
              <div
                style={{
                  marginTop: '1rem',
                  fontWeight: '400'
                }}
              >
                {chatMedium}
              </div>
              <Divider sx={{ marginBottom: '1rem', marginTop: '1rem' }} />
              <div
                style={{
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '1rem'
                }}
              >
                <Link
                  to={ROUTES.AGENT.SECURITY_CREDENTIALS}
                  onClick={() => setToggle(false)}
                >
                  Security Credentials
                </Link>
              </div>
              <Button
                variant="contained"
                color="error"
                size="medium"
                startIcon={<LogoutIcon />}
                onClick={() => {
                  handleLogout();
                  setToggle(false);
                }}
                sx={{ textTransform: 'none' }}
              >
                Logout
              </Button>
            </StyledPopover>
          </>
        ) : (
          <StyledLink to={ROUTES.AGENT.LOGIN} onClick={() => setToggle(false)} color={organisation_custom_info?.loginLabelColor}>
            {
              organisation_custom_info?.loginLabel || "Login"
            }
          </StyledLink>
        )}
      </Menu>
    </Bar>
  );
};

// Exports:
export default Navbar;
