import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import 'draft-js/dist/Draft.css';
import uuid from 'react-uuid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import levenshtein from 'fast-levenshtein';
import { IconButton, Tooltip, LinearProgress, Button } from '@mui/material';

// Redux:
import { getContentActions } from '../../redux/actions/contentActions';

// Styles:
import {
  Wrapper,
  Article,
  Information,
  Options,
  Header,
  Icons
} from './styles';

// Components:
import {
  Access,
  ImportCheckbox,
  DescriptionComponent,
  FooterComponent,
  GoBackComponent,
  Iframe,
  Source,
  Synonyms,
  Target,
  TagTypeInputComponent,
  TitleComponent,
  CommentBox,
  CustomMetadata
} from '../../components/KnowledgeArticleV2';
import { ResourceBox } from '../../components';

// Functions:
import {
  fetchCommentAPI,
  addCommentAPI,
  getArticle,
  getArticleContent,
} from '../../api';
import { useDeepCompareEffect } from 'react-use';
import {
  deleteArticle,
  isArticleValid
} from '../../lib/articles/commonOperations';
import {
  saveDraft,
  submitDraft
} from '../../lib/articles/draftsOperations';
import { rejectDraft, publishDraft } from '../../lib/articles/reviewOperations';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { urlify } from '../../lib/urlify';
import { toast } from 'react-toastify';

// Constant
import { VALID_URL_REGEX } from '../../constants/regex';
import {
  ACCESS_IS_PUBLIC,
  ARTICLE_IS_DRAFT,
  ARTICLE_IS_PUBLISHED,
  ARTICLE_IS_UNDER_REVIEW
} from '../../constants/articles';
import { ADMIN, AGENT, SUPERVISOR, SUPER_ADMIN } from '../../constants/user';
import ROUTES from '../../constants/routes';

const KnowledgeArticleV2 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = useSelector((state) => state.content.org_info?.org_data?._id);
  const [articleData, setArticleData] = useState(null);
  const agent_type = useSelector((state) => state.content.agent_type);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const query = useSelector((state) => state.content.query);

  // Constants:
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const contentActions = getContentActions(dispatch);

  // State:
  const [isEdit, setIsEdit] = useState(false);
  const [tag, setTag] = useState([]);
  const [trainingPhrase, setTrainingPhrase] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const previousArticleData = useRef(articleData);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [autosaving, setAutosaving] = useState(false);
  const [articleBody, setArticleBody] = useState('<p></p>');
  const [articleComments, setArticleComments] = useState([]);
  const [fetchingComments, setFetchingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [tab, setTab] = useState('chat');
  const [numberOfResources, setNumberOfResources] = useState(0);
  const [showOptions, setShowOptions] = useState(false)

  const didMount = useRef(false);
  const [modalProps, setModalProps] = useState({
    actionButton: '',
    message: '',
    actionFn: null
  });

  // Functions:
  const changeCommentsArray = useCallback((val) => {
    setComments(val);
  }, []);

  const handleDrawerOpen = () => {
    setOpenCollapse(true);
  };

  const handleDrawerClose = () => {
    setOpenCollapse(false);
  };

  const handleModalProps = useCallback((val) => {
    setModalProps(val);
  }, []);
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const currentUser = {
    user: userDetails?.email ?? 'anonymous',
    role: agent_type
  };

  const handleClickOpen = useCallback(async (orgid, articleId) => {
    setFetchingComments(true);
    setOpen(true);
    const response = await fetchCommentAPI(orgid, articleId);
    const comments = response?.comments;
    const newComments = comments?.map((comment) => {
      const createdAt = new Date(comment?.createdAt).toUTCString();
      return { ...comment, createdAt };
    });
    setArticleComments(newComments);
    setFetchingComments(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    changeCommentsArray([]);
  }, [changeCommentsArray]);

  const changeIsEdit = useCallback((val) => {
    setIsEdit(val);
  }, []);

  const changeArticle = useCallback((val) => {
    setArticleData(val);
  }, []);

  const changeArticleBody = useCallback((val) => {
    setArticleBody(val);
  }, []);

  const changeTag = useCallback((val) => {
    setTag(val);
  }, []);

  const changeTrainingPhrase = useCallback((val) => {
    setTrainingPhrase(val);
  }, []);

  const handleShowOptions = (value) => {
    if(value === false)
      setShowOptions(false)
    else {
      if(showOptions === false)
        setShowOptions(true)
      else
        setShowOptions(false)
    }
  }

  const checkPrivileges = (access, status) => {
    if (isLoggedIn
      || (access === ACCESS_IS_PUBLIC
      && status === ARTICLE_IS_PUBLISHED)
    ) {
      return;
    }

    const location = window.location;
    navigate(ROUTES.AGENT.LOGIN, {
      state: {
        path: `${location?.pathname}${location?.search}`
      }
    });
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const initialArticleData = locationState?.initialArticleData;
      const editState = locationState?.isEdit ?? false;
      let tempArticle = {};

      if (initialArticleData) { // coming from FAQ page
        tempArticle = { ...initialArticleData };
        if (!tempArticle.meta?.importFromSource &&
            !tempArticle.meta?.formattedText) {
          const status = tempArticle.meta.status;
          const createdBy = status === ARTICLE_IS_DRAFT
            ? userDetails?.email
            : tempArticle.meta.createdBy;
          const response = await getArticleContent(orgId,{
            articleId: tempArticle.articleId,
            version: tempArticle.meta.version,
            status,
            createdBy
          }, currentUser);
          initialArticleData.meta.formattedText = urlify(
            response?.data?.formattedText ?? ""
          );
        }
      } else {
        const status = searchParams.get('status');
        const createdBy = status === ARTICLE_IS_DRAFT
          ? userDetails?.email
          : searchParams.get('createdBy');
        const response = await getArticle(orgId, {
          articleId: searchParams.get('articleId'),
          version: searchParams.get('version'),
          status,
          createdBy
        }, currentUser);
        tempArticle = response?.data?.article;
      }
      checkPrivileges(tempArticle?.meta?.access, tempArticle?.meta?.status);
      setIsEdit(editState);
      setArticleData(tempArticle ?? {});
      setArticleBody(urlify(tempArticle?.meta?.formattedText ?? '<p></p>'));
      previousArticleData.current = tempArticle;
    } catch (error) {
      toast.error("Failed to load article");
      console.error(error);
    }
    setIsLoading(false);
  }, [
    orgId,
    isLoggedIn,
    searchParams,
    locationState?.initialArticleData,
    locationState?.isEdit,
    userDetails?.email
  ]);

  const autoSaveCallback = useCallback(
    async (incomingArticle) => {
      setAutosaving(true);
      try {
        await saveDraft(incomingArticle, currentUser);
      } catch (error) {
        console.error(error);
        toast.error("Failed to autosave draft")
      }
      setAutosaving(false);
    },
    [userDetails?.email, orgId]
  );

  const handlePublish = async () => {
    if (!isArticleValid(articleData)) {
      return;
    }
    if (
      agent_type.toLowerCase() === SUPERVISOR ||
      agent_type.toLowerCase() === SUPER_ADMIN || 
      agent_type.toLowerCase() === ADMIN
      ) {
      setIsLoading(true);
      try {
        const newData = await publishDraft({
          ...articleData,
          meta: {
            ...articleData.meta,
            publishedBy: userDetails?.email
          },
          currentUser
        });
        const queryParams = {
          articleId: newData.articleId,
          status: newData.meta?.status ?? 'NA',
          version: newData.meta?.version ?? 'NA'
        };
        const queryString = Object.keys(queryParams)
          .map((key) => key + '=' + queryParams[key])
          .join('&');
        navigate(`${ROUTES.KNOWLEDGE_ARTICLE_V2}?${queryString}`, {
          state: {
            initialArticleData: newData,
            isEdit: false
          }
        });
      } catch (error) {
        toast.error("Failed to publish draft");
        console.error(error);
      }
      setIsLoading(false);
    } else {
      toast.error("Only admins can publish a submitted draft");
    }
  };

  const handleDraftRejection = async () => {
    setIsLoading(true);
    try {
      await rejectDraft(articleData, currentUser);
      navigate(`${ROUTES.AGENT.KNOWLEDGE_V2}`)
    } catch (error) {
      toast.error("Failed to reject draft");
      console.error(error);
    }
    setIsLoading(false);
  }

  const handleDraftSubmission = async () => {
    if (!isArticleValid(articleData)) {
      return;
    }
    setIsLoading(true);
    try {
      const newData = await submitDraft(articleData, currentUser);
      const queryParams = {
        articleId: newData.articleId,
        status: newData.meta?.status ?? 'NA',
        createdBy: newData.meta?.createdBy ?? 'NA'
      };
      const queryString = Object.keys(queryParams)
        .map((key) => key + '=' + queryParams[key])
        .join('&');
      navigate(`${ROUTES.KNOWLEDGE_ARTICLE_V2}?${queryString}`, {
        state: {
          initialArticleData: newData,
          isNew: false,
          isEdit: false
        }
      });
    } catch (error) {
      toast.error("Failed to submit draft");
      console.error(error);
    }
    setIsLoading(false);
  }

  const saveArticleCallback = async () => {
    setIsLoading(true);
    try {
      await saveDraft(articleData, currentUser);
      setIsEdit(false);
    } catch (error) {
      toast.error("Failed to save draft");
      console.error(error);
    }
    setIsLoading(false);
  };

  const deleteArticleCallback = async () => {
    setIsLoading(true);
    try {
      await deleteArticle(articleData, userDetails?.email, agent_type);
      navigate(`${ROUTES.AGENT.KNOWLEDGE_V2}`);
    } catch (error) {
      toast.error("Failed to delete draft");
      console.error(error);
    }
    setIsLoading(false);
  };

  const addReaction = async (type) => {
    try {
      const data = {
        orgid: orgId,
        user_email: `${userDetails?.email || 'anonymous'}`,
        type,
        subtype: "article",
        article_id: articleData?.articleId,
        title: articleData?.meta?.title,
        query
      };
      await contentActions.reactOnArticle(data);
    } catch (error) {
      console.error(error);
    }
  };

  const onTargetChange = (event) => {
    const target = event.target.value;
    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        target: event.target.value,
        access:
          target.toLowerCase() === 'agent' || target.toLowerCase() === 'partner'
            ? 'private'
            : articleData.meta.access
      }
    };
    setArticleData(newArticle);
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const onAccessChange = (event) => {
    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        access: event.target.value
      }
    };
    setArticleData(newArticle);
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const addTag = () => {
    const tags = articleData?.meta?.tags
      ? [...articleData?.meta?.tags, { id: uuid(), desc: tag }]
      : [{ id: uuid(), desc: tag }];

    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        tags
      }
    };
    setArticleData(newArticle);
    setTag('');
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const deleteTag = (id) => {
    const newTags = articleData?.meta?.tags?.filter((tag) => tag.id !== id);
    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        tags: newTags
      }
    };
    setArticleData(newArticle);
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const addTrainingPhrase = () => {
    const trainingPhrases = articleData?.meta?.trainingPhrases
      ? [...articleData?.meta?.trainingPhrases, { id: uuid(), desc: trainingPhrase }]
      : [{ id: uuid(), desc: trainingPhrase }];

    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        trainingPhrases
      }
    };
    setArticleData(newArticle);
    setTrainingPhrase('');
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const deleteTrainingPhrase = (id) => {
    const newTrainingPhrases = articleData?.meta?.trainingPhrases?.filter(
      (tag) => tag.id !== id
    );

    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        trainingPhrases: newTrainingPhrases
      }
    };
    setArticleData(newArticle);
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const handleAddComment = useCallback(
    async (comment, changeComment) => {
      const isPublished = articleData?.meta?.status === ARTICLE_IS_PUBLISHED;
      if (comment !== '' && !isPublished) {
        let newId = comments.length + 1;
        const d = new Date();
        let newDateTime = d.toString().substring(0, 24);
        let newComment = {
          id: newId,
          createdAt: newDateTime,
          comment: comment,
          author: userDetails?.email
        };
        changeCommentsArray([...comments, newComment]);
        changeComment('');
        await addCommentAPI(
          orgId,
          articleData?.articleId || articleData?.meta?.articleId,
          comment,
          userDetails?.email
        );
      }
    },
    [
      articleData?.articleId,
      articleData?.createdBy,
      articleData?.meta,
      articleData?.question,
      changeCommentsArray,
      comments,
      orgId,
      userDetails?.email
    ]
  );

  // Effects

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useDeepCompareEffect(() => {
    let interval;
    if (!didMount.current) {
      if (
        isEdit &&
        articleData &&
        Object?.keys(articleData)?.length > 1 &&
        (!Object.keys(articleData?.meta).includes('published') ||
          (Object.keys(articleData?.meta).includes('published') &&
            articleData?.meta?.published === false))
      ) {
        const prevData = previousArticleData.current;
        interval = setInterval(async () => {
          if (!autosaving) {
            await autoSaveCallback(articleData);
          }
        }, 25000);
        (async () => {
          const titleDistance =
            articleData?.meta?.title?.length > 0 &&
            prevData?.meta?.title?.length > 0
              ? levenshtein.get(
                  articleData?.meta?.title,
                  prevData?.meta?.title
                )
              : -1;

          const sourceDistance =
            articleData?.meta?.source?.length > 0 &&
            prevData?.meta?.source?.length > 0
              ? levenshtein.get(
                  articleData?.meta?.source,
                  prevData?.meta?.source
                )
              : -1;

          const bodyDistance =
            articleData?.meta?.formattedText?.length > 0 &&
            prevData?.meta?.formattedText?.length > 0
              ? levenshtein.get(
                  articleData?.meta?.formattedText,
                  prevData?.meta?.formattedText
                )
              : -1;

          if (
            titleDistance > 15 ||
            sourceDistance > 10 ||
            bodyDistance > 50
          ) {
            if (!autosaving) {
              await autoSaveCallback(articleData);
              previousArticleData.current = articleData;
            }
          }
        })();
      }
    } else {
      didMount.current = true;
    }

    return () => {
      clearInterval(interval);
    };
  }, [articleData, autoSaveCallback, isEdit]);

  const isUserCreator = userDetails?.email === articleData?.meta?.createdBy;

  const rejectButtonLabel = isUserCreator ? "Withdraw" : "Reject";
  // Return:
  return (
    <Wrapper>
      {open && (
        <CommentBox
          open={open}
          handleClose={handleClose}
          agent_type={agent_type}
          documentId={articleData?.articleId || articleData?.meta?.articleId}
          handleAddComment={handleAddComment}
          storeComments={articleComments}
          loading={fetchingComments}
          changeCommentsArray={changeCommentsArray}
          comments={comments}
        />
      )}

      <ConfirmationDialog
        title={modalProps?.title}
        message={modalProps?.message}
        performAction={modalProps?.actionFn}
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        actionButton={modalProps?.actionButton}
      />

      <Article>
        <Information isEdit={isEdit} showOptions={showOptions}>
          <Header>
            <GoBackComponent
              isEdit={isEdit}
              autosaveFn={autoSaveCallback}
              articleData={articleData}
              autosaving={autosaving}
            />
            {!isEdit && (
              <Icons>
                {agent_type.toLowerCase() !== 'enduser' &&
                  !articleData?.meta?.published && (
                    <Tooltip title="Info">
                      <IconButton
                        onClick={() =>
                          handleClickOpen(
                            orgId,
                            articleData?.articleId ||
                              articleData?.meta?.articleId
                          )
                        }
                      >
                        <InfoOutlinedIcon
                          color="primary"
                          sx={{ fontSize: 25 }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {(agent_type.toLowerCase() === SUPERVISOR ||
                  agent_type.toLowerCase() === SUPER_ADMIN ||
                  agent_type.toLowerCase() === AGENT || agent_type.toLowerCase() === ADMIN) && (
                    <Button 
                    variant="contained"
                    color="success"
                    disabled={articleData?.meta?.status !== ARTICLE_IS_DRAFT}
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                      handleOpenModal();
                      handleModalProps({
                        actionButton: "Submit for review",
                        title:'Submit for review',
                        message:
                          'Are you sure you want to submit this article for review?',
                        actionFn: () => handleDraftSubmission()
                      });
                    }}
                  >
                    Submit for review
                  </Button>
                )}

                {(agent_type.toLowerCase() === SUPERVISOR ||
                  agent_type.toLowerCase() === SUPER_ADMIN || agent_type.toLowerCase() ===ADMIN) && (
                  <Button 
                    variant="contained"
                    color="success"
                    disabled={articleData?.meta?.status !== ARTICLE_IS_UNDER_REVIEW}
                    style={{ textTransform: 'none', marginLeft: '10px' }}
                    onClick={() => {
                      handleOpenModal();
                      handleModalProps({
                        actionButton: "Publish",
                        title: 'Article approval is pending',
                        message: 'Are you sure you want to publish this article',
                        actionFn: () => handlePublish()
                      });
                    }}
                  >
                    Publish
                  </Button>
                )}
                {(agent_type.toLowerCase() === SUPERVISOR ||
                  agent_type.toLowerCase() === SUPER_ADMIN ||
                  agent_type.toLowerCase() === ADMIN ||
                  (agent_type.toLowerCase() === AGENT && isUserCreator)) && (
                  <Button 
                    variant="contained"
                    color="error"
                    disabled={articleData?.meta?.status !== ARTICLE_IS_UNDER_REVIEW}
                    style={{ textTransform: 'none', marginLeft: '10px' }}
                    onClick={() => {
                      handleOpenModal();
                      handleModalProps({
                        actionButton: rejectButtonLabel,
                        title: `${rejectButtonLabel} article`,
                        message:
                          `Are you sure you want to ${rejectButtonLabel.toLowerCase()} this article?`,
                        actionFn: () => handleDraftRejection()
                      });
                    }}
                  >
                    {rejectButtonLabel}
                  </Button>
                )}

                {articleData?.meta?.source !== '' && !openCollapse && (
                  <Tooltip title="View Original Source">
                    <IconButton onClick={handleDrawerOpen}>
                      <OpenInNewOutlinedIcon
                        color="primary"
                        sx={{ fontSize: 25 }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Icons>
            )}
          </Header>

          {!(
            (articleData && Object?.keys(articleData)?.length < 2) ||
            isLoading ||
            !articleData
          ) && (
            <TitleComponent
              isEdit={isEdit}
              articleData={articleData}
              changeArticle={changeArticle}
            />
          )}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <Source
              isEdit={isEdit}
              articleData={articleData}
              changeArticle={changeArticle}
            />

            <ImportCheckbox
              isEdit={isEdit}
              changeArticle={changeArticle}
              articleData={articleData}
            />
          </div>

          {(articleData && Object?.keys(articleData)?.length < 2) ||
          isLoading ||
          !articleData ? (
            <LinearProgress />
          ) : (
            <DescriptionComponent
              isEdit={isEdit}
              articleData={articleData}
              changeArticle={changeArticle}
              articleBody={articleBody}
              changeArticleBody={changeArticleBody}
            />
          )}
        </Information>
        {isEdit && (
          <Options isEdit={isEdit} showOptions={showOptions}>
            <Target onTargetChange={onTargetChange} articleData={articleData} />
            <Access onAccessChange={onAccessChange} articleData={articleData} />
            <TagTypeInputComponent
              fieldName={'tags'}
              placeholder={'Enter tags'}
              path={articleData?.meta?.tags}
              articleData={articleData}
              deleteTag={deleteTag}
              addTag={addTag}
              changeTag={changeTag}
              tag={tag}
            />

            <TagTypeInputComponent
              fieldName={'training phrases'}
              placeholder={'Enter training phrases'}
              path={articleData?.meta?.trainingPhrases}
              articleData={articleData}
              deleteTag={deleteTrainingPhrase}
              addTag={addTrainingPhrase}
              changeTag={changeTrainingPhrase}
              tag={trainingPhrase}
            />

            <Synonyms
              articleData={articleData}
              title="Synonyms"
              buttonTitle="Add New Synonym"
              autosaveFn={autoSaveCallback}
              changeArticle={changeArticle}
            />

            <CustomMetadata articleData={articleData} changeArticle={changeArticle} />
          </Options>
        )}
        <FooterComponent
          changeIsEdit={changeIsEdit}
          changeArticle={changeArticle}
          saveArticle={saveArticleCallback}
          isEdit={isEdit}
          articleData={articleData}
          deleteArticle={deleteArticleCallback}
          addReaction={addReaction}
          handleOpenModal={handleOpenModal}
          handleModalProps={handleModalProps}
          publishingArticle={isLoading}
          handleShowOptions={handleShowOptions}
        />
      </Article>

      <ResourceBox
        tab={tab}
        setTab={setTab}
        previewSource={null}
        setNumberOfResources={setNumberOfResources}
        openCollapse={openCollapse &&
          VALID_URL_REGEX.test(articleData?.meta?.source ?? "")}
      />

      {openCollapse &&
        VALID_URL_REGEX.test(articleData?.meta?.source ?? "") && (
          <Iframe
            handleDrawerClose={handleDrawerClose}
            openCollapse={openCollapse}
            source={articleData.meta.source}
          />
        )}
    </Wrapper>
  );
};

// Exports:
export default KnowledgeArticleV2;
